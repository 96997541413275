



































































































import mixins from 'vue-typed-mixins'
import DraftElement from '@/calendesk/sections/section/mixins/DraftElement'
import SelectableService from '@/calendesk/models/SelectableService'
import Service from '@/calendesk/models/DTO/Response/Service'
import ServiceType from '@/calendesk/models/DTO/Response/ServiceType'
import Dialog from '@/calendesk/models/Dialog'
import { DialogTypes } from '@/components/dialogs/DialogTypes'
import DialogSize from '@/calendesk/models/DialogSize'
import VCardWrapper from '@/calendesk/sections/section/calendars/calendarV2/components/VCardWrapper.vue'

export default mixins(DraftElement).extend({
  components: { VCardWrapper },
  props: {
    selectableService: {
      type: SelectableService,
      required: true
    },
    textColor: {
      type: String,
      default: null
    },
    color: {
      type: String,
      default: null
    },
    disableChanging: {
      type: Boolean,
      default: false
    },
    changeMode: {
      type: Boolean,
      default: false
    },
    hideBookingEndTime: {
      type: Boolean,
      default: false
    },
    classForViewRound: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      selectedService: null as Service | null,
      truncateDescription: true
    }
  },
  computed: {
    service (): Service {
      return this.selectableService.service
    },
    hasTypes (): boolean {
      return this.service.types && this.service.types.length > 0
    },
    getPrice (): string | null {
      if (this.changeMode) {
        return `${(this.$options.filters as any).money(this.selectableService.getPrice())}`
      } else {
        if (this.hasTypes) {
          const minPrice = this.service.types.reduce((min, type) => Math.min(min, type.price), Number.MAX_SAFE_INTEGER)
          const maxPrice = this.service.types.reduce((max, type) => Math.max(max, type.price), Number.MIN_SAFE_INTEGER)

          if (minPrice !== maxPrice) {
            return `${(this.$options.filters as any).money(minPrice)} - ${(this.$options.filters as any).money(maxPrice)}`
          }

          return `${(this.$options.filters as any).money(minPrice)}`
        }

        return `${(this.$options.filters as any).money(this.service.price)}`
      }
    },
    getDuration (): string | null {
      if (this.changeMode) {
        return `${(this.$options.filters as any).durationFilter(this.selectableService.getDuration())}`
      } else {
        if (this.hasTypes) {
          const minDuration = this.service.types.reduce((min, type) => Math.min(min, type.duration), Number.MAX_SAFE_INTEGER)
          const maxDuration = this.service.types.reduce((max, type) => Math.max(max, type.duration), Number.MIN_SAFE_INTEGER)

          if (minDuration !== maxDuration) {
            return `${(this.$options.filters as any).durationFilter(minDuration)} - ${(this.$options.filters as any).durationFilter(maxDuration)}`
          }

          return `${(this.$options.filters as any).durationFilter(minDuration)}`
        }

        return `${(this.$options.filters as any).durationFilter(this.service.duration)}`
      }
    }
  },
  methods: {
    update () {
      this.selectedService = this.service
      if (!this.changeMode &&
          this.selectedService &&
          this.selectedService.types &&
          this.selectedService.types.length > 0) {
        this.openDialog(
          new Dialog(
            true,
            DialogTypes.BOOKING_CALENDAR_SERVICE_TYPES_DIALOG,
            this.$trans('select_service_type'),
            DialogSize.LARGE,
            false,
            'center',
            {
              service: this.selectedService,
              textColor: this.textColor,
              color: this.color,
              callback: (serviceType: ServiceType) => {
                this.$emit('change', new SelectableService(this.selectedService as Service, serviceType, this.selectableService.numberOfServicesInCategory))
              }
            }))
      } else {
        this.$emit('change', this.changeMode ? null : new SelectableService(this.selectedService as Service, null, this.selectableService.numberOfServicesInCategory))
      }
    },
    reset () {
      this.$emit('change', null)
    }
  }
})
