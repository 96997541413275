














































































































import mixins from 'vue-typed-mixins'
import DraftElement from '@/calendesk/sections/section/mixins/DraftElement'
import CCalendarLocationPanel
  from '@/calendesk/sections/section/calendars/calendarV2/components/CCalendarLocationPanel.vue'
import SelectableLocation from '@/calendesk/models/SelectableLocation'
import CCalendarService from '@/components/CCalendarService.vue'
import CCalendarServicePanel
  from '@/calendesk/sections/section/calendars/calendarV2/components/CCalendarServicePanel.vue'
import SelectableService from '@/calendesk/models/SelectableService'
import SelectableEmployee from '@/calendesk/models/SelectableEmployee'
import SelectableCategory from '@/calendesk/models/SelectableCategory'
import CCalendarEmployeePanel
  from '@/calendesk/sections/section/calendars/calendarV2/components/CCalendarEmployeePanel.vue'

export default mixins(DraftElement).extend({
  name: 'CalendarPanels',
  components: {
    CCalendarEmployeePanel,
    CCalendarServicePanel,
    CCalendarService,
    CCalendarLocationPanel
  },
  props: {
    selectLocationsTitle: {
      type: String,
      default: ''
    },
    selectServicesTitle: {
      type: String,
      default: ''
    },
    selectEmployeesTitle: {
      type: String,
      default: ''
    },
    classForViewRound: {
      type: String,
      default: ''
    },
    backgroundColor: {
      type: String,
      default: null
    },
    textColor: {
      type: String,
      default: null
    },
    isLoadingPreferredEmployee: {
      type: Boolean,
      default: false
    },
    isSettingRandomEmployeeSupported: {
      type: Boolean,
      default: false
    },
    hideBookingEndTime: {
      type: Boolean,
      default: false
    },
    selectableLocations: {
      type: Array,
      default: () => Array<SelectableLocation>()
    },
    selectableCategories: {
      type: Array,
      default: () => Array<SelectableCategory>()
    },
    selectableEmployees: {
      type: Array,
      default: () => Array<SelectableEmployee>()
    }
  },
  data () {
    return {
      selectedSelectableLocation: null as SelectableLocation | null,
      selectedSelectableService: null as SelectableService | null,
      selectedSelectableEmployee: null as SelectableEmployee | null
    }
  },
  computed: {
    getSelectableLocations (): Array<SelectableLocation> {
      return this.selectableLocations as Array<SelectableLocation>
    },
    getSelectableCategories (): Array<SelectableCategory> {
      return this.selectableCategories as Array<SelectableCategory>
    },
    getSelectableEmployees (): Array<SelectableEmployee> {
      return this.selectableEmployees as Array<SelectableEmployee>
    }
  },
  watch: {
    selectableLocations () {
      this.checkIfOnlyOneLocationShouldBeSelected()
    },
    selectableEmployees () {
      this.checkIfOnlyOneEmployeeShouldBeSelected()
    },
    selectedSelectableService () {
      this.checkIfOnlyOneLocationShouldBeSelected()
      this.checkIfOnlyOneEmployeeShouldBeSelected()
    }
  },
  created () {
    this.checkIfOnlyOneLocationShouldBeSelected()
    this.checkIfOnlyOneServiceShouldBeSelected()
  },
  methods: {
    checkIfOnlyOneServiceShouldBeSelected () {
      this.$nextTick(() => {
        const anyLocationSelected = this.selectedSelectableLocation
        const noLocationsToSelect = !this.selectableLocations || this.selectableLocations.length === 0

        if (anyLocationSelected || noLocationsToSelect) {
          if (this.selectableCategories && this.selectableCategories.length === 1 &&
              (this.selectableCategories as SelectableCategory[])[0].selectableServices.length > 0) {
            const services = (this.selectableCategories[0] as SelectableCategory).selectableServices
            if (services.length === 1 && (!services[0].service.types || services[0].service.types.length === 0)) {
              this.serviceSelected(services[0])
            }
          }
        }
      })
    },
    checkIfOnlyOneLocationShouldBeSelected () {
      this.$nextTick(() => {
        if (!this.selectedSelectableLocation &&
            this.selectableLocations &&
            this.selectableLocations.length === 1) {
          // Select the only one location by default
          this.locationSelected(this.selectableLocations[0] as SelectableLocation)
        }
      })
    },
    checkIfOnlyOneEmployeeShouldBeSelected () {
      this.$nextTick(() => {
        if (this.selectedSelectableService &&
            !this.selectedSelectableEmployee &&
            this.getSelectableEmployees &&
            this.getSelectableEmployees.length > 0) {
          // Any employee should be selected by default,
          // or if there is only one employee, select them.
          const firstEmployeeIsAnyEmployee = this.getSelectableEmployees[0].isAny
          if (this.getSelectableEmployees.length === 2 && firstEmployeeIsAnyEmployee) {
            this.employeeSelected(this.getSelectableEmployees[1])
          } else if (firstEmployeeIsAnyEmployee || this.getSelectableEmployees.length === 1) {
            this.employeeSelected(this.getSelectableEmployees[0])
          }
        }
      })
    },
    locationSelected (selectableLocation: SelectableLocation | null) {
      this.serviceSelected(null)

      this.selectedSelectableLocation = selectableLocation
      this.$emit('location-changed', this.selectedSelectableLocation)
      this.checkIfOnlyOneServiceShouldBeSelected()
    },
    serviceSelected (selectableService: SelectableService | null) {
      this.employeeSelected(null)

      this.selectedSelectableService = selectableService
      this.$emit('service-changed', this.selectedSelectableService)
    },
    handleUserSelectsEmployee (selectableEmployee: SelectableEmployee) {
      if (!selectableEmployee.isAny) {
        selectableEmployee.manuallySelected = true
      }

      this.employeeSelected(selectableEmployee)
    },
    employeeSelected (selectableEmployee: SelectableEmployee | null) {
      this.selectedSelectableEmployee = selectableEmployee
      this.$emit('employee-changed', this.selectedSelectableEmployee)
    }
  }
})
