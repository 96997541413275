



























































import mixins from 'vue-typed-mixins'
import DraftElement from '@/calendesk/sections/section/mixins/DraftElement'
import LocationItem from '@/components/LocationItem.vue'
import SelectableLocation from '@/calendesk/models/SelectableLocation'
import VCardWrapper from '@/calendesk/sections/section/calendars/calendarV2/components/VCardWrapper.vue'

export default mixins(DraftElement).extend({
  components: {
    VCardWrapper,
    LocationItem
  },
  props: {
    selectableLocation: {
      type: SelectableLocation,
      default: null
    },
    textColor: {
      type: String,
      default: null
    },
    color: {
      type: String,
      default: null
    },
    disableChanging: {
      type: Boolean,
      default: false
    },
    changeMode: {
      type: Boolean,
      default: false
    },
    classForViewRound: {
      type: String,
      default: ''
    }
  },
  methods: {
    update () {
      this.$emit('change', this.changeMode ? null : this.selectableLocation)
    }
  }
})
